/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

//--ion-font-family: 'Helvetica Regular';
//--ion-font-family-light: 'Helvetica Light Regular';
//--ion-font-family-bold: 'Helvetica Bold';
//--ion-font-family-rounded: 'Helvetica Rounded';
//--ion-font-family-rounded-bold: 'Helvetica Rounded Bold';

//--ion-color-primary: #000000;             /** black **/
//--ion-color-primary-contrast: #ffffff;    /** white **/
//--ion-color-secondary: #33594f;           /** clover **/
//--ion-color-tertiary: #3861da;            /** royal blue **/
//--ion-color-success: #48a48c;             /** chateau green **/
//--ion-color-dark: #472c2c;                /** espresso **/
//--ion-color-medium: #a6a6a6;              /** dark gray **/
//--ion-color-light: #f7f7f7;               /** lynx white **/
//--ion-color-light-shade: #efeee9;         /** alabaster **/

//--ion-spacing-normal: '0px';
//--ion-spacing-wide: '+0.144px';
//--ion-spacing-condensed-a: '-0.24px';
//--ion-spacing-condensed-b: '-0.416px';
//--ion-spacing-condensed-c: '-0.496px';
//--ion-spacing-condensed-d: '-0.608px';

.menu-name {
  font-family: var(--ion-font-family), sans-serif;
  font-size: 40px !important;
  color: var(--ion-color-primary-contrast);
  letter-spacing: var(--ion-spacing-normal);
}

.menu-item-name {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 18.67px !important;
  color: var(--ion-color-dark);
  letter-spacing: var(--ion-spacing-normal);
}

.category-name-header {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 18.40px !important;
  color: var(--ion-color-dark);
  letter-spacing: var(--ion-spacing-normal);
}

.sub-category-name-header {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 14px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-normal);
  text-transform: uppercase;
}

.options-selections-header {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 18px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-condensed-d);
}

.pop-up-headline {
  font-family: var(--ion-font-family-bold), sans-serif;
  font-size: 18px !important;
  color: var(--ion-color-dark);
  letter-spacing: var(--ion-spacing-condensed-b);
}

.item-name {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 18px !important;
  color: var(--ion-color-dark);
  letter-spacing: var(--ion-spacing-normal);
  line-height: 20px;
}

.item-name_no_color {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 18px !important;
  letter-spacing: var(--ion-spacing-normal);
  line-height: 20px;
}

.category-item-subhead {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 15.47px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-wide);
}

.standard-caps {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 12px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-wide);
  text-transform: uppercase;
}

.item-description-body {
  font-family: var(--ion-font-family), sans-serif;
  font-size: 18px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-condensed-a);
  line-height: 20px;
  padding-top: 8px;
}

.pop-up-message-body {
  font-family: var(--ion-font-family), sans-serif;
  font-size: 16.67px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-normal);
}

.option-description {
  font-family: var(--ion-font-family), sans-serif;
  font-size: 18px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-wide);
}

.restaurant-description {
  font-family: var(--ion-font-family), sans-serif;
  font-size: 17.33px !important;
  color: var(--ion-color-light-shade);
  letter-spacing: var(--ion-spacing-normal);
}

.required-response {
  font-family: var(--ion-font-family-bold), sans-serif;
  font-size: 16px !important;
  color: var(--ion-color-success);
  letter-spacing: var(--ion-spacing-condensed-d);
}

.optional-response {
  font-family: var(--ion-font-family-bold), sans-serif;
  font-size: 16px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-condensed-d);
}

.countdown {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 16.8px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-normal);
}

.category-description {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 16.53px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-condensed-c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.sub-category-description {
  font-family: var(--ion-font-family-rounded-bold), sans-serif;
  font-size: 14px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-condensed-c);
  text-transform: uppercase;
}

.menu-subhead-categories {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 17.33px !important;
  //color: var(--ion-color-light-shade);
  letter-spacing: var(--ion-spacing-condensed-d);
}

.item-subhead {
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 16px !important;
  color: var(--ion-color-medium);
  letter-spacing: var(--ion-spacing-wide);
}

ion-modal.auto-height {
  --height: auto;

  .modal-wrapper, .modal-shadow {
    position: fixed;
    bottom: 0;
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content-40 {
      max-height: 40vh;
      overflow: auto;
    }

    .inner-content-60 {
      max-height: 60vh;
      overflow: auto;
    }

    .inner-content-95 {
      max-height: 95vh;
      overflow: auto;
    }
  }
}
.modal-backdrop-opacity {
  --backdrop-opacity: 0.4;
}
::-webkit-scrollbar, *::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
.green-button {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-light);
  --border-radius: 30px !important;
  width: -webkit-fill-available;
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 17px !important;
  letter-spacing: var(--ion-spacing-wide);
  height: 50px !important;
}
.button-disabled {
  --background: var(--ion-color-medium) !important;
}
ion-fab-button {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-light);
  --border-radius: 30px !important;
  width: 90vw;
  margin-left: 5vw;
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 17px !important;
  letter-spacing: var(--ion-spacing-wide);
  height: 50px !important;
}
.countdown-button {
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-medium);
  --border-radius: 30px !important;
  width: -webkit-fill-available;
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 16.6px !important;
  letter-spacing: var(--ion-spacing-normal);
  height: 50px !important;
  text-transform: none;
}
.order-button {
  --background: var(--ion-color-light) !important;
  --color: var(--ion-color-dark);
  --border-radius: 30px !important;
  width: -webkit-fill-available;
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 23.4px !important;
  letter-spacing: var(--ion-spacing-normal);
  height: 50px !important;
  z-index: 10;
}
.number-button {
  --background: var(--ion-color-light) !important;
  --color: var(--ion-color-dark);
  width: -webkit-fill-available;
  font-family: var(--ion-font-family-rounded), sans-serif;
  font-size: 17.6px !important;
  letter-spacing: var(--ion-spacing-wide);
  height: 50px !important;
  margin: 0 -25px !important;
  z-index: 1;
}
ion-card {
  border-radius: 0;
}
ion-list.no-last-border {
  ion-item:last-child {
    --border-style: none !important;
  }
}
app-menu-item {
  width: 100%;
}
.col-flex-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-flex-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.col-flex-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.col-flex-center-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.view-bottom-padding {
  padding-bottom: 70px;
}
.no-scroll {
  --overflow: hidden;
}

/* Begin stripe css */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: var(--ion-color-purewhite);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 98%;
  margin-top: 5px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
/* End stripe css */

