// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #000000;             /** black **/
  --ion-color-primary-rgb: 0,0,0;
  --ion-color-primary-contrast: #ffffff;    /** white **/
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #1a1a1a;

  --ion-color-secondary: #000000;           /** clover **/
  --ion-color-secondary-rgb: 51,89,79;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2d4e46;
  --ion-color-secondary-tint: #476a61;

  --ion-color-tertiary: #3861da;            /** royal blue **/
  --ion-color-tertiary-rgb: 56,97,218;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #3155c0;
  --ion-color-tertiary-tint: #4c71de;

  --ion-color-success: #48a48c;            /** chateau green **/
  --ion-color-success-rgb: 72,164,140;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #3f907b;
  --ion-color-success-tint: #5aad98;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #b8860b;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #472c2c;             /** espresso **/
  --ion-color-dark-rgb: 71,44,44;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #3e2727;
  --ion-color-dark-tint: #594141;

  --ion-color-medium: #a6a6a6;           /** dark gray **/
  --ion-color-medium-rgb: 166,166,166;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #abaaaa;
  --ion-color-medium-tint: #d4d3d3;

  --ion-color-light: #f7f7f7;            /** lynx white **/
  --ion-color-light-rgb: 247,247,247;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #efeee9;     /** alabaster **/
  --ion-color-light-tint: #f8f8f8;

  --theme-white: #ffffff;
  --theme-alabaster: #efeee9;
  --theme-lynx-white: #f7f7f7;
  --theme-dark-gray: #a6a6a6;
  --theme-espresso: #472c2c;
  --theme-chateau-green: #48a48c;
  --theme-clover: #33594f;
  --theme-royal-blue: #3861da;

  --ion-font-family: 'Helvetica Regular';
  --ion-font-family-light: 'Helvetica Light Regular';
  --ion-font-family-bold: 'Helvetica Bold';
  --ion-font-family-rounded: 'Helvetica Rounded';
  --ion-font-family-rounded-bold: 'Helvetica Rounded Bold';

  --ion-spacing-normal: '0px';
  --ion-spacing-wide: '+0.144px';
  --ion-spacing-condensed-a: '-0.24px';
  --ion-spacing-condensed-b: '-0.416px';
  --ion-spacing-condensed-c: '-0.496px';
  --ion-spacing-condensed-d: '-0.608px';
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #f7f7f7;
//    --ion-color-primary-rgb: 247,247,247;
//    --ion-color-primary-contrast: #000000;
//    --ion-color-primary-contrast-rgb: 0,0,0;
//    --ion-color-primary-shade: #efeee9;
//    --ion-color-primary-tint: #f8f8f8;
//
//    --ion-color-secondary: #33594f;
//    --ion-color-secondary-rgb: 51,89,79;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #2d4e46;
//    --ion-color-secondary-tint: #476a61;
//
//    --ion-color-tertiary: #3861da;
//    --ion-color-tertiary-rgb: 56,97,218;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #3155c0;
//    --ion-color-tertiary-tint: #4c71de;
//
//    --ion-color-success: #48a48c;
//    --ion-color-success-rgb: 72,164,140;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #3f907b;
//    --ion-color-success-tint: #5aad98;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #472c2c;
//    --ion-color-dark-rgb: 71,44,44;
//    --ion-color-dark-contrast: #ffffff;
//    --ion-color-dark-contrast-rgb: 255,255,255;
//    --ion-color-dark-shade: #3e2727;
//    --ion-color-dark-tint: #594141;
//
//    --ion-color-medium: #a6a6a6;
//    --ion-color-medium-rgb: 166,166,166;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #929292;
//    --ion-color-medium-tint: #afafaf;
//
//    --ion-color-light: #121212;
//    --ion-color-light-rgb: 18,18,18;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #101010;
//    --ion-color-light-tint: #2a2a2a;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-toolbar-background: #0d0d0d;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
